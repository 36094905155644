
.title{
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 30px;
    color: #F0F0F0;
}

p {
    color: #606060;
    font-size: 15px;
}