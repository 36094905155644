
.font-span {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 30px;
    color: #808080;
}

.features {
    display: flex;
    justify-content: center; /* Center child elements horizontally */
    align-items: center; /* Center child elements vertically, optional */
    padding-top: 100px;
    width: 100%; /* Occupy full width */
}

.features-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items within the column */
    gap: 20px;
    width: 100%; /* Fill the parent’s width */
    max-width: 800px; /* Set max width for responsiveness */
    margin: 0 auto; /* Center it within its parent */
}

.feature-box {
    background-color: #161616;
    border-radius: 8px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 80%; /* Full width of the container */
    max-width: 600px; /* Optional max width for consistency */
}
.icon {
    font-size: 40px;
    margin-bottom: 10px;
}

h3 {
    margin: 10px 0;
    font-size: 20px;
    color: #808080;
}

p {
    color: #606060;
    font-size: 18px;
}

