
.title-text {
    display: block;
    text-align: center; /* Center the text */
    font-size: 34px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    color: #54FDB9;
  }

  .note-text {
    display: block;
    text-align: center; /* Center the text */
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    color: #54FDB9;
  }

  
/*
.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center; 
    max-width: 90%; 
    margin: 0 auto; 
    padding: 20px;
    box-sizing: border-box;
  }

  .card {
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: calc(100% / 5 - 30px);
    aspect-ratio: 3 / 4;
    box-sizing: border-box;
    position: relative; 
  }
  
  .card-content {
    position: absolute; 
    top: 0px; 
    left: 5px;
    width: 100%;
    text-align: left;
    padding: 5px 0; 
    font-family: 'Lato', sans-serif;
    font-weight: 200;
    font-size: 20px;
    color: #54FDB9;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5) 
  }

  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
*/

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  max-width: 1500px;  /* A more fixed max-width for fluid layout */
  margin: 0 auto;
  padding: 20px;
}

.card {
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: calc(20% - 20px);  /* Updated for a consistent fluid width */
  aspect-ratio: 3 / 4;  /* Enforcing a specific aspect ratio */
  position: relative;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  position: absolute; 
  top: 0px; 
  left: 5px;
  width: 100%;
  text-align: left;
  padding: 5px 0; 
  font-family: 'Lato', sans-serif;
  font-weight: 200;
  font-size: 20px;
  color: #54FDB9;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .5) 
}

  .button-container {
    display: flex;
    justify-content: center; /* Center the button container */
    margin-top: 20px; /* Add space between the cards and button */
  }
  
  .fetchMore-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF; /* Example button color */
    color: white;
    border-radius: 5px;
    cursor: pointer;
    /* Add other styling like hover effects, borders, etc., as needed */
  }
