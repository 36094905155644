.beta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .message-success {
    color: #54FDB9;
  }
  
  .message-error {
    color: #ff0000;
  }
  
  .form-container {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-field {
    background-color: #202020;
    border: 0px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
  }
  
  .submit-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:disabled {
    background-color: #6c757d; /* light gray color */
    color:#d3d3d3 ; /* dark gray color */
    cursor: not-allowed;
    pointer-events: none;
    transition: none;
  }
  /* Optionally, if you want to ensure there's no hover effect */
  .submit-button.disabled:hover {
    background-color: #d3d3d3;
  }


  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .recaptcha-container {
    display: flex;
    justify-content: center;
  }

.submit-container{
  display: flex;
  flex-direction: row;
}