
.header-container {
    position: relative;
    background: #121212;
    padding: 20px 30px;
  }
  
  .header-background-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px; 
    /*background: url('../public/background7.png') center 20% / cover no-repeat;*/
    filter: blur(30px); 
    z-index: 0;
    background-color: #191919;
  }
  
  .header-content {

    z-index: 1;
    display: flex; /* Add this to enable Flexbox */
    align-items: center; /* Vertically center the items */
    justify-content: space-between; /* Ensure space between logo and navigation */
  }
  
  .logo {
    font-family: "Lobster", cursive;
    font-size: 32px;
    color: #54FDB9;
    font-weight: normal;
    z-index: 1;
  }
  
  .navigation{
    display: flex;
    right: "20px";
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 200;
    z-index: 1;
  }
  
  .navigation nav a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
  }

.header-background-effect:before,
.header-background-effect:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 50px; /* Adjust height for the gradient */
  background: linear-gradient(180deg, rgba(18,18,18,1) 0%, transparent 100%);
  z-index: -1;
}

.header-background-effect:after {
  top: auto;
  bottom: 0;
  background: linear-gradient(0deg, rgba(18,18,18,1) 0%, transparent 100%);
}


.header-container .navigation nav .link-normal {
  font-weight:bold; /* Example: font weight change for active link */
  font-family: 'YourFont', sans-serif; /* Use your desired font family */
  color: #808080; /* Example: color change for active link */
  /* Add any other styles you want to apply to active links */
}

.header-container .navigation nav .link-selected {
  font-weight: bold;
  font-size: 16px; /* example */
  font-family: 'YourFont', sans-serif; 
  color: #54FDB9; /* Make sure this is a color that contrasts with the background */
}
