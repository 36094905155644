.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 55px;
  width: fit-content;
  height: fit-content;
}

.inner-div {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  overflow: hidden;
  z-index: 1;
}
.base-image {
  display: block;
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  -webkit-user-drag: none; /* For Safari and Chrome */
  
}

.overlay-image {
  position: absolute;
  top: 0;
  left: -150px;
  width: auto;
  height: 100%;
  max-width: none;
  transition: transform 1s ease-in-out;
  -webkit-user-drag: none; /* For Safari and Chrome */

}