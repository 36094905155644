
.joke-text{
    font-family: "Lato", sans-serif;
    font-weight: 200;
    font-size: 20px;
    color: #909090;
} 
.joke-text-title{
    font-family: 'Lobster', sans-serif;
    font-weight: 900;
    font-size: 25px;
    font-style: italic;
    color: #F0F0F0;

}